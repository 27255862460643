import { Button, Card, Col, Descriptions, Divider, Form, Input, Modal, Row, Steps, Switch, Typography } from "antd";
import { useState } from "react";
import { RightOutlined, LeftOutlined } from "@ant-design/icons";

import type { DescriptionsProps } from "antd";
import axios from "axios";

const { Meta } = Card;

const QuoteEnligne = () => {
  const [current, setCurrent] = useState(0);
  const [open, setOpen] = useState(false);
  const [form] = Form.useForm();
  const [formInfo] = Form.useForm();
  const [infos, setInfo] = useState({});
  const [carInfos, setCarInfos] = useState({});

  type CarType = {
    marque?: string;
    vin?: string;
    livraison?: boolean;
    articles: string;
    km: string;
    note: string;
  };

  type InfosType = {
    name?: string;
    email?: string;
    phone?: string;
  };

  const next = () => {
    switch (current) {
      case 1:
        form
          .validateFields()
          .then((values: any) => {
            setCurrent(2);
            setCarInfos(values);
            window.scrollTo(0, 0);
            return true;
          })
          .catch((errorInfo) => {
            return false;
          });
        break;
      case 2:
        formInfo
          .validateFields()
          .then((values: any) => {
            setInfo(values);
            setCurrent(3);
            window.scrollTo(0, 0);
            return true;
          })
          .catch((errorInfo) => {
            return false;
          });
        break;

      default:
        break;
    }
    if (current === 1) return true;
  };

  const items: DescriptionsProps["items"] = [
    {
      key: "1",
      label: "Marque",
      children: <span>{form.getFieldValue("marque")}</span>,
    },
    {
      key: "2",
      label: "Numéros de chassis",
      children: <span>{form.getFieldValue("vin")}</span>,
    },
    {
      key: "3",
      label: "Articles",
      children: <span>{form.getFieldValue("articles")}</span>,
    },
    {
      key: "4",
      label: "Kilométrage",
      children: <span>{form.getFieldValue("km") ?? "-"}</span>,
    },
    {
      key: "5",
      label: "Note",
      children: <span>{form.getFieldValue("note") ?? "-"}</span>,
    },
    {
      key: "6",
      label: "Livraison",
      children: <span>{form.getFieldValue("livraison") ? "oui" : "non"}</span>,
    },
  ];

  const itemsInfo: DescriptionsProps["items"] = [
    {
      key: "1",
      label: "Nom",
      children: <span>{formInfo.getFieldValue("name")}</span>,
    },
    {
      key: "2",
      label: "Email",
      children: <span>{formInfo.getFieldValue("email")}</span>,
    },
    {
      key: "3",
      label: "Téléphone",
      children: <span>{formInfo.getFieldValue("phone")}</span>,
    },
  ];

  const validate = () => {
    axios
      .post("https://back.cody.tn/enlignequote/add", {
        infos,
        carInfos,
      })
      .then((res) => {
        setOpen(true);
      });
  };

  const onClose = () => {
    window.location.reload();
  };

  return (
    <div id='achat' className='auto--overflow'>
      {current === 0 && (
        <div className='smtv--quoteenligne-container' style={{ paddingBottom: "1vh" }}>
          <Row>
            <Col span={24} className='smtv--quoteenligne-header-blue'>
              <Row className='smtv--quoteenligne-header' style={{ width: "100%" }}>
                <Col xl={2} md={2} xs={0} />

                <Col xl={20} md={20} xs={24}>
                  <img src='/images/logo-original.png' alt='smtv-logo' />
                </Col>
                <Col xl={2} md={2} xs={0} className='smtv--quoteenligne-header1' />
              </Row>
            </Col>
          </Row>
          <Row className='smtv--quoteenligne-header-blue'>
            <Col xl={2} md={2} xs={0} />

            <Col xl={20} md={20} xs={24}>
              <Row style={{ width: "100%" }}>
                <Col xl={2} md={2} xs={0} />

                <Col xl={20} md={24} xs={24}>
                  <Row>
                    <Col span={24} style={{ alignItems: "center", textAlign: "center" }}>
                      <p>
                        <Typography.Title level={1} style={{ fontWeight: "bold", color: "#fff" }}>
                          Trouvez Vos Pièces Auto de Qualité chez nous
                        </Typography.Title>
                      </p>
                      <p>
                        <Typography.Title level={4} style={{ alignItems: "center", textAlign: "center", color: "#fff" }}>
                          Votre destination de confiance pour toutes vos pièces auto de qualité. <br />
                          Nous comprenons l'importance de maintenir votre véhicule en parfait état de fonctionnement,
                          <br />
                          c'est pourquoi nous vous proposons une vaste sélection de pièces auto adaptées à vos besoins.
                        </Typography.Title>
                      </p>

                      <Row justify='space-between' style={{ alignItems: "center", textAlign: "center", marginTop: "2vh" }}>
                        <Col xl={2} lg={2} md={2} xs={1} />

                        <Col xl={20} lg={20} md={20} xs={22}>
                          <p>
                            <Typography.Title level={2} style={{ fontWeight: "bold", color: "#fff" }}>
                              Demandez votre devis gratuit !
                            </Typography.Title>
                          </p>
                          <p>
                            <Typography.Title level={4} style={{ alignItems: "center", textAlign: "center", color: "#fff" }}>
                              Simplifiez votre expérience d'achat en demandant un devis personnalisé dès maintenant.
                            </Typography.Title>
                          </p>
                        </Col>
                        <Col xl={2} lg={2} md={2} xs={1} />
                      </Row>
                      <Row>
                        <Col xl={8} md={6} xs={4} />

                        <Col xl={8} md={12} xs={16}>
                          <Button
                            onClick={() => {
                              setCurrent(1);
                              window.scrollTo(0, 0);
                            }}
                            className='smtv--devisenligne-button-red'>
                            Demander votre devis
                          </Button>
                        </Col>
                        <Col xl={8} md={6} xs={4} />
                      </Row>

                      <Row justify='space-between' style={{ alignItems: "center", textAlign: "center", marginTop: "1vh" }}>
                        <Col xl={0} lg={0} md={0} xs={4} />
                        <Col xl={8} lg={8} md={8} xs={16}>
                          <Card className='smtv--quoteenligne-card'>
                            <img className='smtv--quoteenligne-card-img' alt='example' src='/images/large.svg' />
                            <Meta
                              title={
                                <Typography.Title level={4} style={{ color: "#3050C3" }}>
                                  Large Sélection
                                </Typography.Title>
                              }
                            />
                          </Card>
                        </Col>
                        <Col xl={0} lg={0} md={0} xs={4} />
                        <Col xl={0} lg={0} md={0} xs={4} />

                        <Col xl={8} lg={8} md={8} xs={16}>
                          <Card className='smtv--quoteenligne-card'>
                            <img className='smtv--quoteenligne-card-img' alt='example' src='/images/qualite.svg' />
                            <Meta
                              title={
                                <Typography.Title level={4} style={{ color: "#3050C3" }}>
                                  Qualité Certifiée
                                </Typography.Title>
                              }
                            />
                          </Card>
                        </Col>
                        <Col xl={0} lg={0} md={0} xs={4} />
                        <Col xl={0} lg={0} md={0} xs={4} />

                        <Col xl={8} lg={8} md={8} xs={16}>
                          <Card className='smtv--quoteenligne-card'>
                            <img className='smtv--quoteenligne-card-img' alt='example' src='/images/expert.svg' />
                            <Meta
                              title={
                                <Typography.Title level={4} style={{ color: "#3050C3" }}>
                                  Conseils d'Experts
                                </Typography.Title>
                              }
                            />
                          </Card>
                        </Col>
                        <Col xl={0} lg={0} md={0} xs={4} />
                      </Row>
                    </Col>
                  </Row>
                </Col>
                <Col xl={2} md={2} xs={0} className='smtv--quoteenligne-header1' />
              </Row>
              <br />
            </Col>
            <Col xl={2} md={2} xs={0} />
          </Row>
        </div>
      )}
      {/* //infos voiture */}
      {current > 0 && (
        <>
          <div className='smtv--quoteenligne-container' style={{ paddingBottom: "1vh" }}>
            <Row>
              <Col span={24} className='smtv--quoteenligne-header-blue'>
                <Row className='smtv--quoteenligne-header' style={{ width: "100%" }}>
                  <Col xl={2} md={2} xs={0} />

                  <Col xl={20} md={20} xs={24}>
                    <img src='/images/logo-original.png' alt='smtv-logo' />
                  </Col>
                  <Col xl={2} md={2} xs={0} className='smtv--quoteenligne-header1' />
                </Row>
              </Col>
            </Row>
            <Row className='smtv--quoteenligne-header-blue'>
              <Col xl={2} md={2} xs={0} />

              <Col xl={20} md={20} xs={24}>
                <Row style={{ width: "100%" }}>
                  <Col xl={2} md={2} xs={0} />

                  <Col xl={20} md={24} xs={24}>
                    <Row>
                      <Col span={24} style={{ alignItems: "center", textAlign: "center" }}>
                        <p>
                          <Typography.Title level={1} style={{ fontWeight: "bold", color: "#fff" }}>
                            Demandez votre devis gratuit !
                          </Typography.Title>
                        </p>
                        <p>
                          <Typography.Title level={4} style={{ alignItems: "center", textAlign: "center", color: "#fff" }}>
                            Ne perdez plus de temps à chasser les prix des pièces auto !<br /> Notre fonctionnalité devis en ligne vous offre la
                            réponse ,dans les brefs délais,
                            <br /> dont vous avez besoin pour entretenir votre voiture sans tracas.
                          </Typography.Title>
                        </p>
                      </Col>
                    </Row>
                  </Col>
                  <Col xl={2} md={2} xs={0} className='smtv--quoteenligne-header1' />
                </Row>
                <br />
              </Col>
              <Col xl={2} md={2} xs={0} />
            </Row>
          </div>
          <Row style={{ marginTop: "2vh" }}>
            <Col xl={5} md={3} xs={0} />
            <Col xl={14} md={18} xs={24}>
              <Card>
                <Steps
                  items={[
                    {
                      title: <span className='title-item'>Informations sur votre voiture</span>,
                      icon: <img style={{ width: "30px", height: "30px" }} src='/images/car.svg' />,
                    },

                    {
                      title: <span className='title-item'>Vos Informations</span>,
                      icon: (
                        <>
                          {current < 2 ? (
                            <img style={{ width: "30px", height: "30px" }} src='/images/info_white.svg' />
                          ) : (
                            <img style={{ width: "30px", height: "30px" }} src='/images/info.svg' />
                          )}
                        </>
                      ),
                    },
                    {
                      title: <span className='title-item'>Résumé</span>,
                      icon: (
                        <>
                          {current < 3 ? (
                            <img style={{ width: "30px", height: "30px" }} src='/images/resume_white.svg' />
                          ) : (
                            <img style={{ width: "30px", height: "30px" }} src='/images/resume.svg' />
                          )}
                        </>
                      ),
                    },
                  ]}
                />
                {current === 1 && (
                  <Form form={form} layout={"vertical"} style={{ marginTop: "2vh" }}>
                    <Row justify='space-between'>
                      <Col xl={12} md={12} lg={12} xs={24}>
                        <Form.Item<CarType> name='marque' label='Marque' rules={[{ required: true, message: "Champ obligatoire!" }]}>
                          <Input className='devis--input' />
                        </Form.Item>
                      </Col>
                      <Col xl={12} md={12} lg={12} xs={24}>
                        <Form.Item<CarType>
                          name='vin'
                          label='Numéros de chassis'
                          rules={[{ required: true, message: "Champ obligatoire 17 caractères!", len: 17 }]}>
                          <Input className='devis--input' />
                        </Form.Item>
                      </Col>
                    </Row>
                    <Row justify='space-between'>
                      <Col xl={12} md={12} lg={12} xs={24}>
                        <Form.Item<CarType> name='articles' label='Article(s)' rules={[{ required: true, message: "Champ obligatoire!" }]}>
                          <Input.TextArea rows={5} className='devis--input' style={{ marginTop: "5px" }} />
                        </Form.Item>
                      </Col>
                      <Col xl={12} md={12} lg={12} xs={24}>
                        <Form.Item<CarType> name='km' label='Kilométrage'>
                          <Input className='devis--input' />
                        </Form.Item>
                      </Col>
                    </Row>
                    <Row justify={"space-between"}>
                      <Col xl={12} md={12} lg={12} xs={24}>
                        <Form.Item<CarType> name='note' label='Note'>
                          <Input className='devis--input' />
                        </Form.Item>
                      </Col>
                      <Col xl={12} md={12} lg={12} xs={24}>
                        <Form.Item<CarType> name='livraison' label='Livraison(gratuite sur le grand Tunis)'>
                          <Switch style={{ float: "left" }}></Switch>
                        </Form.Item>
                      </Col>
                    </Row>
                  </Form>
                )}
                {current === 2 && (
                  <Form form={formInfo} layout={"vertical"} style={{ marginTop: "2vh" }}>
                    <Row justify='space-between'>
                      <Col xl={12} md={12} lg={12} xs={24}>
                        <Form.Item<InfosType> name='name' label='Nom' rules={[{ required: true, message: "Champ obligatoire!" }]}>
                          <Input className='devis--input' />
                        </Form.Item>
                      </Col>
                      <Col xl={12} md={12} lg={12} xs={24}>
                        <Form.Item<InfosType>
                          name='email'
                          label='Email'
                          rules={[{ required: true, type: "email", message: "Merci d'ajouter un email valide!" }]}>
                          <Input className='devis--input' />
                        </Form.Item>
                      </Col>
                    </Row>
                    <Row justify='space-between'>
                      <Col xl={12} md={12} lg={12} xs={24}>
                        <Form.Item<InfosType> name='phone' label='Téléphone' rules={[{ required: true, message: "Champ obligatoire!" }]}>
                          <Input className='devis--input' style={{ marginTop: "5px" }} />
                        </Form.Item>
                      </Col>
                    </Row>
                  </Form>
                )}
                {current === 3 && (
                  <div style={{ marginTop: "3vh" }}>
                    <Descriptions
                      column={{ xs: 1, xxl: 2, xl: 2, lg: 2, md: 2 }}
                      bordered={true}
                      labelStyle={{
                        //fontWeight: "bold",
                        fontSize: "1rem",
                      }}
                      contentStyle={{
                        fontWeight: "bold",
                        color: "#3050C3",
                        fontSize: "1rem",
                      }}
                      title={
                        <span className='title-item' style={{ fontSize: "1.4rem" }}>
                          Informations sur votre voiture
                        </span>
                      }
                      items={items}
                    />

                    <Descriptions
                      column={{ xs: 1, xl: 2, xxl: 2, lg: 2, md: 2 }}
                      bordered={true}
                      labelStyle={{
                        //fontWeight: "bold",
                        fontSize: "1rem",
                      }}
                      contentStyle={{
                        fontWeight: "bold",
                        color: "#3050C3",
                        fontSize: "1rem",
                      }}
                      title={
                        <span className='title-item' style={{ fontSize: "1.4rem" }}>
                          Vos informations
                        </span>
                      }
                      items={itemsInfo}
                    />
                  </div>
                )}
                <Row justify={"space-between"} style={{ marginTop: "4vh" }}>
                  <Col span={12}>
                    {current > 1 && (
                      <Button onClick={() => setCurrent(current - 1)} icon={<LeftOutlined />} className='devis--btn-blue' type='primary'>
                        Retour
                      </Button>
                    )}
                  </Col>
                  <Col span={12}>
                    <Button onClick={() => (current === 3 ? validate() : next())} icon={<RightOutlined />} className='devis--btn-blue' type='primary'>
                      {current === 3 ? "Valider" : "Suivant"}
                    </Button>
                  </Col>
                </Row>
              </Card>
            </Col>
            <Col xl={5} md={3} xs={0} />
          </Row>
        </>
      )}
      <Modal
        footer={[]}
        open={open}
        width={1000}
        onCancel={() => onClose()}
        title={
          <>
            <Typography.Title level={2} style={{ fontWeight: "bold", color: "#3050C3" }}>
              Toute l'équipe SMTV vous remercie pour votre confiance !
            </Typography.Title>
            <Divider />
          </>
        }>
        <p
          style={{
            color: "#3050C3",
            fontSize: "1rem",
          }}>
          Nous tenons à vous exprimer toute notre gratitude pour avoir choisi <b>SMTV</b> pour répondre à vos besoins . Nous sommes ravis que vous
          ayez choisi notre entreprise et nous sommes impatients de vous offrir une expérience exceptionnelle.{" "}
        </p>
        <p
          style={{
            color: "#3050C3",
            fontSize: "1rem",
          }}>
          Chez <b>SMTV</b>, nous nous engageons à fournir des produits/services de haute qualité et un service clientèle exceptionnel. Nous sommes
          convaincus que vous serez pleinement satisfait de votre choix et que notre collaboration sera fructueuse.
        </p>
        <p
          style={{
            color: "#3050C3",
            fontSize: "1rem",
          }}>
          Votre demande sera prise en charge par l'un de nos experts et vous recevrez une réponse dans les plus brefs délais.{" "}
        </p>
        <p
          style={{
            marginTop: "1vh",
            color: "#3050C3",
            fontSize: "1rem",
          }}>
          Encore une fois, merci de votre confiance en nous. Nous sommes honorés de vous avoir parmi nos précieux clients.
        </p>
      </Modal>
      {/* <div style={{ marginTop: "3vh", display: "inline-flex" }}>
        <img src='/images/location.svg' className='devis-enligne-img-footer' />

        <Typography.Title level={4} style={{ color: "#3050C3" }}>
          23 et 59, Av.Hédi Chaker -Tunis
        </Typography.Title>
        <img src='/images/phone.svg' className='devis-enligne-img-footer' />
        <Typography.Title level={4} style={{ color: "#3050C3" }}>
          70 316 316
        </Typography.Title>
        <img src='/images/mobile.svg' className='devis-enligne-img-footer' />
        <Typography.Title level={4} style={{ color: "#3050C3" }}>
          99 316 316
        </Typography.Title>
      </div> */}
    </div>
  );
};
export default QuoteEnligne;
